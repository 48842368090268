.services {
    padding: var(--y-padding) var(--x-padding);

    @include ipad {
        padding: 10rem 4rem;
    }

    @include phone {
        padding: 10rem 2.5rem;
    }

    &__title {
        margin-bottom: var(--y-padding);

        @include ipad {
            margin-bottom: 10rem;
        }
    }

    &__grid {
        --surface-gap: 4rem;

        @include ipad {
            --surface-gap: 2.5rem;
        }

        @include phone {
            --surface-gap: 8rem;
        }
    }


}

.services-item {

    // Default Variable Colors
    --theme-color: var(--primary);
    --theme-text: #fff;
    --surface-height: 50rem;
    --surface-max-width: 80rem;
    --image-max-width: 36rem;
    --text-max-width: 50rem;

    display: flex;

    @include respond(50em) {
        --image-max-width: 25rem;
    }

    @include phone {
        display: block;
        --surface-height: 32rem;
    }

    &:nth-child(odd) > div:first-child {
        margin-right: var(--surface-gap);
    }

    &:nth-child(even) {
        flex-direction: row-reverse;
        & > div:last-child {
            margin-right: var(--surface-gap);
        }
    }

    &:not(:last-child) {
        margin-bottom: var(--surface-gap);
    }

    &__image {
        max-width: var(--image-max-width);
        width: 100%;
        height: var(--surface-height);

        @include phone {
            max-width: 100%;
            margin: 0 auto !important;
        }

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    &__surface {
        max-width: var(--surface-max-width);
        min-height: var(--surface-height);
        width: 100%;

        &.wide {
            max-width: calc(var(--surface-max-width) + var(--image-max-width) + var(--surface-gap));

            @include ipad {
                max-width: 100%;
                width: 100%;
                margin-right: 0 !important;
            }
        }

        display: flex;
        justify-content: center;
        align-items: center;

        background-color: var(--theme-color);
    }

    &__container {
        max-width: var(--text-max-width);

        @include ipad {
            padding: 0 3rem;
        }

        @include phone {
            padding: 6rem 4rem;
        }
    }

    &__title {
        margin-bottom: 2rem;
        color: var(--theme-text);
    }

    &__text {
        margin-bottom: 3rem;
        color: var(--theme-text);
        opacity: .7;
    }


    // THEMES
    &.mint {
        --theme-color: #D4F2DB;
        --theme-text: var(--heading-color);
    }

    &.nude {
        --theme-color: #BA9790;
    }

    &.watermelon {
        --theme-color: #B97078;
    }


}