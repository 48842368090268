body {

    background-color: var(--background-color);
    width: 100vw;
    overflow-x: hidden;

    &.fixed {
        height: 100vh;
        
    }
    
}

label, input[type="text"], input[type="email"], input[type="password"], input[type="number"], select, textarea {
    display: block;
    &:focus { outline: none; }
}


::selection {
    color: #fff;
    background: rgba($primary, .6);
}

ul {
    list-style: none;
}

img {
    display: block;
    max-width: 100%;
}

hr {
    display: block;
    border: none;
    background-color: var(--font-color);
    height: 2px;
    opacity: .5;
}