.navbar {

    --navbar-anchor-size: 2.7rem;

    height: var(--navbar-height);
    width: 100vw;
    padding: 0 var(--navbar-padding);

    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;

    display: flex;
    justify-content: space-between;
    align-items: center;

    background-color: rgba(#F5EFF3, .6);
    backdrop-filter: blur(25px);

    @include phone {
        padding: 0 2rem;
    }

    &__icon {

        height: var(--navbar-icon-size);
        width: var(--navbar-icon-size);
        padding: var(--navbar-icon-padding);

        position: relative;
        display: none;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        background-color: transparent;
        color: var(--heading-color);

        border: none;
        cursor: pointer;


        &:focus {
            outline: none;
        }

        &-line {

            display: block;
            height: var(--navbar-icon-line-thickness);
            width: 100%;
            margin: var(--navbar-icon-line-thickness) 0;

            background-color: currentColor;

        }
    }

    &__brand {
        padding: var(--navbar-icon-padding);
        display: block;
    }

    &__logo {
        height: var(--navbar-height);
        width: 22rem;
        color: var(--heading-color);
    }

    &__menu {
        //display: none;
        height: var(--navbar-height);
        display: flex;
        align-items: center;

        @include ipad {

            position: fixed;
            top: 0;
            left: 0;
            z-index: -1;

            flex-direction: column;
            justify-content: center;

            height: 100vh;
            width: 100vw;
            background-color: white;

            transform: translateX(-105%);
            pointer-events: none;

            transition: transform .85s ease;
        }
    }

    &.open &__menu {
        @include ipad {
            transform: translateX(0);
            pointer-events: all;
        }
    }

    &__divider {
        height: 2.7rem;
        width: 2px;
        border: none;
        background-color: currentColor;
        margin: 0 3rem;

        @include ipad {
            display: none;
        }
    }

    &__links {
        display: flex;

        @include ipad {
            flex-direction: column;
            align-items: center;

            margin-bottom: 10rem;
        }
    }

    &__link {

        color: var(--heading-color);

        &:not(:last-child) {
            margin-right: 3rem;

            @include ipad {
                margin-right: 0;
                margin-bottom: 4rem;
            }
        }

        a {
            @include link {
                color: currentColor;

                @include ipad {
                    font-size: var(--h3)
                }
            }
        }

        &.active {
            color: var(--primary);
            position: relative;

            &:hover::after {
                height: 2rem;
                opacity: .25;
                z-index: -1;
            }

            &::after {
                content: '';
                position: absolute;
                display: block;

                height: 2px;
                width: 100%;

                background-color: currentColor;
                bottom: -6px;
                cursor: pointer;

                transition: all $dt;
            }
        }
    }

    &__social {

        display: flex;

        a {
            display: block;
            height: var(--navbar-anchor-size);
            width: var(--navbar-anchor-size);

            @include ipad {
                height: 4rem;
                width: 4rem;
            }
        }

        li:not(:last-child) {
            margin-right: 3rem;
        }

        svg {
            height: 100%;
            width: 100%;
        }

    }

    @include ipad {
        &__icon {
            display: flex;
        }
    }

    /* Condensed Navbar Styles */
    &.condensed &__icon {
        display: flex;
    }
}