$btn-rounded-radius: .5rem;
$btn-light-text: #fff;
$btn-dark-text: #333;
$btn-border-width: 2px;

@mixin btn-text-style {
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 1.7;
}

@mixin btn-uppercase-style {
    font-weight: 700;
    font-size: 1.4rem;
}