:root {

    --x-padding: #{$x-padding};
    --y-padding: #{$y-padding};

    --navbar-height: #{$navbar-height};
    --navbar-icon-size: #{$navbar-icon-size};
    --navbar-icon-line-thickness: #{$navbar-icon-line-thickness};
    --navbar-icon-padding: #{$navbar-icon-padding};

    --navbar-padding: #{$navbar-padding};

    @include ipad {
        --navbar-padding: #{$navbar-padding-tablet};
        --x-padding: #{$x-padding-tablet};
        --y-padding: #{$y-padding-tablet};
    }

    @include phone {
        --navbar-padding: #{$navbar-padding-mobile};
        --x-padding: #{$x-padding-phone};
        --y-padding: #{$y-padding-phone};
    }

    @include iphone {
        --x-padding: #{$x-padding-iphone};
    }

}