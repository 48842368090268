// Font Families
$body-font: 'Poppins', Helvetica, Arial, sans-serif;
$heading-font: 'Poppins', Helvetica, Arial, sans-serif;


// Letter Spacing 
$letter-spacing: .15em;
$letter-spacing-wide: .2em;
$letter-spacing-condensed: .1em;


// Font Sizes
$h1: 6.5rem;
$h2: 4.9rem;
$h3: 3.7rem;
$h4: 2.8rem;
$h5: 2.2rem;
$h6: 1.8rem;
$p: 1.8rem;
$micro: 2rem;


// Font Weights
$body-font-weight: 400;
$heading-large-font-weight: 300;
$heading-medium-font-weight: 400;
$heading-small-font-weight: 600;