@mixin link {
    &, &:link, &:visited { @content };
}

@mixin uppercase {
    text-transform: uppercase;
    letter-spacing: .1em;
}

@mixin flexCenter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@mixin overlay {
    position: absolute;
    top: 0;
    left: 0;
    
    height: 100%;
    width: 100%;
}

@mixin pseudo {
    display: block;
    content: '';
    position: absolute;
}

@mixin cover {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

@mixin grid($columns: repeat(1fr, 12), $rows: auto) {

    display: grid;
    grid-template-columns: $columns;
    grid-template-rows: $rows;

}