.footer {
    background-color: var(--heading-color);
    padding: var(--x-padding);

    @include phone {
        padding: var(--y-padding) var(--x-padding);
    }

    --footer-icon-size: 2.7rem;

    &__content {
        display: flex;
        justify-content: space-between;

        @include ipad {
            display: block;
        }
    }

    &__brand {
        color: #fff;

        height: 6rem;

        svg {
            height: 100%;
            width: 30rem;;
        }
    }

    &__nav {

        display: flex;
        justify-content: flex-end;
        align-items: center;

        @include ipad {
            margin-top: 5rem;
            justify-content: flex-start;
        }

        @include phone {
            flex-wrap: wrap;
        }

        ul {
            display: flex;

            &:not(.footer__social) {
                @include phone {
                    flex-grow: 1;
                    width: 100%;
                    margin-bottom: 1rem;
                    display: block;

                    li {
                        margin-bottom: 1.5rem;
                    }
                }
            }
        }

        li {

            transition: opacity $dt;

            &:hover {
                opacity: .5;
            }

            &:not(:last-child) {
                margin-right: 2.5rem;
            }
            
            a {
                @include link {
                    color: #fff;
                }
            }
        }

        svg {
            height: 100%;
            width: 100%;
        }

    }

    &__social {
        a {
            display: block;
            height: var(--footer-icon-size);
            width: var(--footer-icon-size);
        }
    }

    &__divider {
        height: 3rem;
        width: 3px;
        border: none;
        background-color: rgba(#fff, .5);
        margin: 0 3rem;

        @include phone {
            display: none;
        }
    }

    &__copyright {
        display: flex;
        justify-content: center;

        margin-top: 8rem;

        @include ipad {
            justify-content: flex-start;
        }
        
        p {
            color: #808080;
        }
    }
}