@mixin btnVars {

    // VARIABLES
    --lt-color: currentColor;
    --dk-color: var(--lt-color);

    --lt-color-alt: var(--lt-color);
    --dk-color-alt: var(--lt-color-alt);

    --lt-text: var(--lt-color);
    --dk-text: var(--dk-color);

    --lt-border: transparent;
    --dk-border: var(--lt-border);

    --lt-bg: transparent;
    --dk-bg: var(--lt-bg);

    --lt-opacity: 1;
    --dk-opacity: var(--lt-opacity);

    --lt-underline: var(--lt-color);
    --dk-underline: var(--dk-color);

}


@mixin btnShapes {
    &.wide { width: 100%; }

    &.pill, &.rectangle, &.rounded {
        padding: 1.5rem 3rem;
    }

    // Button Styles
    &.pill {
        border-radius: 500rem;
    }

    &.rounded {
        border-radius: $btn-rounded-radius;
    }

    &.underlined {
        padding-bottom: .7rem;
        position: relative;
        min-width: 0;
        
        &::after {
            content: '';
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            
            width: 100%;
            height: 3px;

            transition: height .2s ease, opacity .2s ease;

            background-color: var(--lt-underline);

            @if $enable-dark-theme {
                @include dark {
                    background-color: var(--dk-underline);
                }
            }
        }

        &:hover {
            &::after {
                height: 65%;
                opacity: .25;
            }
        }
    }
}


@mixin btnStyles {
    &.disabled {
        @include link {
            opacity: 0;
            pointer-events: none;
        }
    }
    

    &.uppercase {
        text-transform: uppercase;
        letter-spacing: $letter-spacing; 
        @include btn-uppercase-style;
    }

    &.hollow,
    &.hover-hollow:hover,
    &.hover-hollow:focus,
    &.lt-hollow,
    &.lt-hover-hollow:hover,
    &.lt-hover-hollow:focus {
        --lt-text: var(--lt-color);
        --lt-bg: transparent;
        --lt-border: currentColor;
    }

    &.dk-hollow,
    &.dk-hover-hollow:hover,
    &.dk-hover-hollow:focus {
        --dk-text: var(--dk-color);
        --dk-bg: transparent;
        --dk-border: currentColor;
    }

    &.filled,
    &.hover-fill:hover,
    &.hover-fill:focus {
        --dk-text: var(--dk-color-alt);
        --dk-bg: var(--dk-color);
        --dk-border: var(--dk-color);
    }

    &.filled,
    &.hover-fill:hover,
    &.hover-fill:focus,
    &.lt-filled,
    &.lt-hover-fill:hover,
    &.lt-hover-fill:focus {
        --lt-bg: var(--lt-color);
        --lt-border: var(--lt-color);
        --lt-text: var(--lt-color-alt);
    }

    &.dk-filled,
    &.dk-hover-fill:hover,
    &.dk-hover-fill:focus {
        --dk-bg: var(--dk-color);
        --dk-border: var(--dk-color);
        --dk-text: var(--dk-color-alt);
    }
}


@mixin btnTheme($name, $scope: "global") {

    $prefix: "";

    @if $scope == "light" {
        $prefix: "lt-";
    }
    @else if $scope == "dark" {
        $prefix: "dk-";
    }

    &.#{$prefix}#{$name},
    &.#{$prefix}hover-#{$name}:hover,
    &.#{$prefix}hover-#{$name}:focus {
        @content;
    }

}

@mixin btnBase {

    @include link {

        color: var(--lt-text);
        opacity: var(--lt-opacity);
        border-color: var(--lt-border);
        background-color: var(--lt-bg);

        user-select: none;

        &:hover, &:focus { opacity: var(--lt-opacity); }
        &:focus { outline: none; }

        @if $enable-dark-theme {

            @include dark {
                color: var(--dk-text);
                opacity: var(--dk-opacity);
                border-color: var(--dk-border);
                background-color: var(--dk-bg);
                &:hover { opacity: var(--dk-opacity); }
            }

        }

    }

}


.btn {


    // PROPERTIES
    display: block;
    min-width: 20rem;
    width: max-content;

    text-align: center;

    border-width: $btn-border-width;
    border-style: solid;

    cursor: pointer;
    transition: all $dt;

    @include btn-text-style;

    @include btnVars;

    @include btnBase;

    @include btnShapes;


    // Global Color Themes
    @include btnTheme("primary") {
        --lt-color: var(--primary);
        --lt-color-alt: #{$btn-light-text};
        --dk-color-alt: #{$btn-dark-text};
        --dk-color: var(--primary);
    }

    @include btnTheme("accent") {
        --lt-color: var(--accent-color);
        --lt-color-alt: #{$btn-light-text};
        --dk-color-alt: #{$btn-dark-text};
        --dk-color: var(--accent-color);
    }

    @include btnTheme("light") {
        --lt-color: #{$btn-light-text};
        --lt-color-alt: #{$btn-dark-text};
    }

    @include btnTheme("dark") {
        --lt-color: #{$btn-dark-text};
        --lt-color-alt: #{$btn-light-text};
    }

    @include btnTheme("text-primary") {
        --lt-color-alt: var(--primary);
        --lt-text: var(--primary);
        --dk-text: var(--primary);
    }


    // Light Color Themes
    @include btnTheme("primary", "light") {
        --lt-color: var(--primary);
        --lt-color-alt: #{$btn-light-text};
    }

    @include btnTheme("accent", "light") {
        --lt-color: var(--accent-color);
        --lt-color-alt: #{$btn-light-text};
    }

    @include btnTheme("light", "light") {
        --lt-color: #{$btn-light-text};
        --lt-color-alt: #{$btn-dark-text};
    }

    @include btnTheme("dark", "light") {
        --lt-color: #{$btn-dark-text};
        --lt-color-alt: #{$btn-light-text};
    }

    @include btnTheme("text-primary", "light") {
        --lt-color-alt: var(--primary);
    }



    // Dark Color Themes
    @include btnTheme("primary", "dark") {
        --dk-color: var(--primary);
        --dk-color-alt: #{$btn-dark-text};
    }

    @include btnTheme("accent", "dark") {
        --dk-color: var(--accent-color);
        --dk-color-alt: #{$btn-dark-text};
    }

    @include btnTheme("light", "dark") {
        --dk-color: #{$btn-light-text};
        --dk-color-alt: #{$btn-dark-text};
    }

    @include btnTheme("dark", "dark") {
        --dk-color: #{$btn-dark-text};
        --dk-color-alt: #{$btn-light-text};
    }

    @include btnTheme("text-primary", "dark") {
        --dk-text: var(--primary);
    }


    // Opacity Hover
    &.opacity-75,
    &.lt-opacity-75,
    &.hover-opacity-75:hover,
    &.lt-hover-opacity-75:hover,
    &.hover-opacity-75:focus,
    &.lt-hover-opacity-75:focus {
        --lt-opacity: .75;
    }

    &.opacity-50,
    &.lt-opacity-50,
    &.hover-opacity-50:hover,
    &.lt-hover-opacity-50:hover,
    &.hover-opacity-50:focus,
    &.lt-hover-opacity-50:focus {
        --lt-opacity: .5;
    }
    
    &.dk-opacity-75,
    &.dk-hover-opacity-75:hover,
    &.dk-hover-opacity-75:focus {
        --dk-opacity: .75;
    }

    &.dk-opacity-50,
    &.dk-hover-opacity-50:hover,
    &.dk-hover-opacity-50:focus {
        --dk-opacity: .5;
    }

    


    // Button Modifiers

    @include btnStyles;
    

}