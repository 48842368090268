:root {

    --h1: #{$h1};
    --h2: #{$h2};
    --h3: #{$h3};
    --h4: #{$h4};
    --h5: #{$h5};
    --h6: #{$h6};
    --p: #{$p};
    --ls: #{$letter-spacing};
    --ls-wide: #{$letter-spacing-wide};
    --ls-condensed: #{$letter-spacing-condensed};

    @include phone {
        --h1: #{$h2};
        --h2: #{$h3};
        --h3: #{$h4};
        --h4: #{$h5};
        --h5: #{$h6};
        --h6: 1.6rem;
        --p: #{$micro};
    }

}


body {
    font-family: $body-font;
    font-size: 1.6rem;
    font-weight: $body-font-weight;
    color: var(--font-color);

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1 { font-size: var(--h1); }
h2 { font-size: var(--h2); }
h3 { font-size: var(--h3); }
h4 { font-size: var(--h4); }
h5 { font-size: var(--h5);}
h6 { font-size: var(--h6); }

h1, h2, h3, h4, h5, h6 {
    color: var(--heading-color);
}

h1, h2 {
    line-height: 1.3;
    font-weight: $heading-large-font-weight;
}

h3, h4 {
    line-height: 1.5;
    font-weight: $heading-medium-font-weight;
}

h5, h6 {
    line-height: 1.5;
    font-weight: $heading-small-font-weight;
    text-transform: uppercase;
    letter-spacing: var(--ls);
}

p, .text, button, a, input {
    font-size: var(--p);
    color: var(--font-color);
}

p, .text {
    line-height: 1.8;
}


h1, h2, h3, h4, h5, h6, p, a:not(.btn), span {

    &.h1 { font-size: var(--h1); }
    &.h2 { font-size: var(--h2); }
    &.h3 { font-size: var(--h3); }
    &.h4 { font-size: var(--h4); }
    &.h5 { font-size: var(--h5); }
    &.h6 { font-size: var(--h6); }
    &.text, &.p { font-size: var(--p); }

    &.uppercase {
        text-transform: uppercase;
        letter-spacing: var(--ls);

        &.condensed { letter-spacing: var(--ls-condensed); }
        &.wide { letter-spacing: var(--ls-wide); }
    }

    &.heading-color { @include link{ color: var(--heading-color); } }
    &.default-font-color { @include link{ color: var(--font-color); } }
    &.primary { @include link{ color: var(--primary); } }
    &.white { @include link{ color: #fff;  } }
    
    &.dim-95 { opacity: .95; }
    &.dim-90 { opacity: .9; }
    &.dim-85 { opacity: .85; }
    &.dim-80 { opacity: .8; }
    &.dim-75 { opacity: .75; }
    &.dim-70 { opacity: .7; }
    &.dim-65 { opacity: .65; }
    &.dim-60 { opacity: .6; }
    &.dim-55 { opacity: .55; }
    &.dim-50 { opacity: .5; }
    &.dim-45 { opacity: .45; }
    &.dim-40 { opacity: .4; }
    &.dim-35 { opacity: .35; }
    &.dim-30 { opacity: .3; }
    &.dim-25 { opacity: .25; }
    &.dim-20 { opacity: .2; }
    &.dim-15 { opacity: .15; }
    &.dim-10 { opacity: .1; }
    &.dim-05 { opacity: .05; }

    &.light { font-weight: 300; }
    &.regular { font-weight: 400; }
    &.medium { font-weight: 500; }
    &.bold { font-weight: 600; }

    &.lh-100 { line-height: 1; }
    &.lh-110 { line-height: 1.1; }
    &.lh-120 { line-height: 1.2; }
    &.lh-130 { line-height: 1.3; }
    &.lh-140 { line-height: 1.4; }
    &.lh-150 { line-height: 1.5; }
    &.lh-160 { line-height: 1.6; }
    &.lh-170 { line-height: 1.7; }
    &.lh-180 { line-height: 1.8; }
    &.lh-190 { line-height: 1.9; }
    &.lh-200 { line-height: 2; }

    &.center { text-align: center; }
    &.left { text-align: left; }
    &.right { text-align: right; }

    &.span-primary { span { color: var(--primary); } }
    &.span-contrast {
        --alpha: .5;
        color: rgba(var(--base-color), var(--alpha));
        span { color: rgba(var(--base-color), 1); }

        &.high { --alpha: .3; }
        &.low { --alpha: .7; }
    }

}

span.primary {
    color: var(--primary);
}

a {

    cursor: pointer;

    @include link {
        text-decoration: none;
        color: var(--font-color);
    }

    &.default {

        transition: color $dt, background-color $dt, opacity $dt, box-shadow $dt, border $dt;

        @include link {
            color: var(--primary);

            &:hover { opacity: .7; }
        }

    }

}

button {
    font-family: $body-font;
}

input, textarea {
    font-family: $body-font;
    font-size: var(--p);
    color: var(--font-color);
    &::placeholder-shown, &::placeholder {
        color: var(--font-color);
        opacity: .6;
    }
}

::placeholder {
    color: var(--font-color);
}