.map {

    position: relative;
    display: flex;
    justify-content: center;

    &__container {
        width: 100%;
        height: 50rem;
        background-color: #F2F2F2;
    }

    &__tooltip {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
    }


    &__window {

        position: absolute;

        pointer-events: none;

        top: 0;

        transform: translateY(-15%) scale(0);
        transition: transform .3s ease;

        padding: 8rem 10rem;

        background-color: var(--primary);

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &.active {
            pointer-events: all;
            transform: translateY(-15%) scale(1);
        }

        h4 {
            color: #fff;
        }

        .btn {
            margin-top: 3rem;
        }

        &-close {
            position: absolute;
            top: 2rem;
            right: 3rem;

            height: 4rem;
            width: 4rem;

            color: #fff;
            cursor: pointer;

            transition: opacity .2s ease;

            &:hover {
                opacity: .7;
            }

            svg {
                height: 100%;
                width: 100%;
            }
        }
    }

}