.cta {

    padding: var(--y-padding) var(--x-padding);
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;

    &__bg {
        position: absolute;
        top: 0;
        left: 0;

        height: 100%;
        width: 100%;

        z-index: -1;

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    &__text {
        color: #fff;
        max-width: 80rem;
        margin-bottom: 6rem;
    }

}