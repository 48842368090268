// Inspired By https://codepen.io/CKH4/pen/ZGNyep

@keyframes slide {
    0% {
        transform: scale(1);
    }
    50% {
        opacity: 0.3;
        transform: scale(2);
    }
    100% {
        transform: scale(1);
    }
}

.loader {
    position: absolute;
    top: 50%;
    left: 40%;
    margin-left: 10%;
    transform: translate3d(-50%, -50%, 0);

    --loader-color-1: #32aacc;
    --loader-color-2: #64aacc;
    --loader-color-3: #96aacc;
    --loader-color-4: #c8aacc;
    --loader-color-5: #faaacc;

    &__dot {
        width: 24px;
        height: 24px;
        background: var(--loader-color-1);
        border-radius: 100%;
        display: inline-block;
        animation: slide 1s infinite;

        &:nth-child(1) {
            animation-delay: 0.1s;
            background: var(--loader-color-1);
        }

        &:nth-child(2) {
            animation-delay: 0.2s;
            background: var(--loader-color-2);
        }

        &:nth-child(3) {
            animation-delay: 0.3s;
            background: var(--loader-color-3);
        }

        &:nth-child(4) {
            animation-delay: 0.4s;
            background: var(--loader-color-4);
        }

        &:nth-child(5) {
            animation-delay: 0.5s;
            background: var(--loader-color-5);
        }
    }
}