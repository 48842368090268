// Reset Base Browser Properties
*, *::before, *::after {
    box-sizing: inherit;
    margin: 0;
    padding: 0;
}


// Allow for easy rem sizing
html {
    font-size: 62.5%;
    scroll-behavior: smooth;

    @include ipad {
        font-size: 56.25%;
    }
    
    @include ipadPro {
        font-size: 50%;
    }
}


body {
    box-sizing: border-box;
    overflow-x: hidden;
}

main {
    overflow-x: hidden;
}