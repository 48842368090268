.fab {

    display: block;
    position: fixed;
    bottom: 6rem;
    right: 6rem;
    z-index: 10;

    height: 6rem;
    width: 6rem;
    padding: 1.5rem;
    border-radius: 50%;

    cursor: pointer;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);

    @include link {
        text-decoration: none;
        color: #fff;
        background-color: var(--secondary);
    }

    @include phone {
        bottom: 3rem;
        right: 2rem;
    }

    svg {
        height: 100%;
        width: 100%;
        fill: currentColor;
    }
}