:root {

    --error-color: #{$error-color};
    --success-color: #{$success-color};
    --warning-color: #{$warning-color};

    --primary: #{$primary};
    --secondary: #{$secondary};
    --tertiary: #{$tertiary};

    --background-color: #{$bg-color};

    --heading-color: #{$heading-color};
    --font-color: #{$font-color};

} 