.modal {

    height: 100vh;
    width: 100vw;

    position: fixed;
    top: 0;
    left: 0;
    z-index: 200;

    transform: translateX(-105%);
    pointer-events: none;

    background-color: #fff;

    overflow-y: scroll;

    &.open {
        pointer-events: all;
    }

    &__nav {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 10rem;
        padding: 0 8rem;

        @include phone {
            padding: 0 2rem;
        }
    }

    &__close {
        height: 5rem;
        width: 5rem;

        cursor: pointer;
        transition: opacity $dt;

        color: var(--heading-color);

        &:hover {
            opacity: .5;
        }

        svg {
            height: 100%;
            width: 100%;
        }
    }

    &__container {
        padding: 0 var(--x-padding);

        &.flex-center {
            padding-top: 15%;
        }
    }

    &__header {

        h3 {
            margin-bottom: 1rem;
        }

        p {
            max-width: 70rem;
        }
    }

    &__divider {
        display: block;
        height: 3px;
        width: 100%;
        max-width: 25rem;

        border: none;

        background-color: var(--primary);
        margin: 3rem 0; 
    }

    &__body {

        margin-bottom: 4.4rem;

        h3 {
            margin-bottom: 2.4rem;
        }

        h4 {
            font-size: var(--h6);
            font-weight: bold;
        }

        & > div:not(:last-child) {
            margin-bottom: 3rem;
        }

        span {
            color: var(--heading-color);
        }

    }

    &__item {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        max-width: 70rem;

        hr {
            height: 3px;
            flex-grow: 1;
            background-color: currentColor;
            border: none;
            opacity: .25;
            margin: 0 5rem;
        }
    }

    &__title {
        margin-bottom: 1.2rem;
        font-size: var(--h2);
    }

    &__link {

        display: block;
        width: max-content;

        margin-top: .8rem;

        @include link {
            color: var(--primary);
        }
    }
    
}