.safety {
    padding: var(--y-padding) var(--x-padding);

   

    &__container {

        @include grid(40% 1fr);
        grid-gap: 10rem;
        max-width: 110rem;

        @include phone {
            grid-template-columns: 1fr;
        }

        align-items: center;

        margin: 0 auto;

    }

    &__title {
        margin-bottom: 3rem;
    }

}