.hero {

    height: 100vh;
    width: 100%;

    display: grid;
    grid-template-columns: 1fr 1fr;

    position: relative;
    
    padding: 0 20rem;

    @include laptop {
        padding: 0 0 0 10rem;
    }

    @include ipad {
        padding: 0 0 0 8rem;
    }

    @include phone {
        grid-template-columns: 1fr;

        padding: 0 4rem;
    }

    @include iphone {
        padding: 0 2.5rem;
    }


    background-color: #F5EFF3;

    &__title {

        margin-bottom: 4rem;

        span {
            font-weight: 400;
            color: var(--primary);
        }

    }


    &__text {
        font-size: var(--p);
        max-width: 55rem;
        line-height: 1.7;
        margin-bottom: 6rem;
    }


    &__content {
        height: 100%;

        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;

        @include phone {
            z-index: 2;
        }

    }

    &__image {
        height: 100vh;

        @include phone {
            position: absolute;
            z-index: 1;
            opacity: .05;
            width: 100vw;
        }

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;

            @include laptop {
                object-position: left;
            }

            @include phone {
                object-position: center;
            }
        }

    }

}