.preloader {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 500;

    height: 100vh;
    width: 100vw;

    background-color: #fff;

    &.complete {
        pointer-events: none;
    }
}