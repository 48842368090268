.appointment {
    
    height: 100vh;
    width: 100vw;

    position: fixed;
    top: 0;
    left: 0;
    z-index: 200;

    background-color: #fff;

    display: flex;
    flex-direction: column;

    transform: translateX(-105%);
    pointer-events: none;

    &.open {
        pointer-events: all;
    }

    &__nav {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 10rem;
        padding: 0 8rem;

        @include phone {
            padding: 0 2rem;
        }
    }

    &__close {
        height: 5rem;
        width: 5rem;

        cursor: pointer;
        transition: opacity $dt;

        color: var(--heading-color);

        &:hover {
            opacity: .5;
        }

        svg {
            height: 100%;
            width: 100%;
        }
    }

    &__container {
        padding: 0 8rem 4rem;
        flex-grow: 1;
    }

    &-shade {
        height: 100vh;
        width: 100vw;

        position: fixed;
        top: 0;
        left: 0;
        z-index: 199;

        background-color: #000;

        opacity: 0;
        pointer-events: none;
    }

}