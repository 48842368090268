.about {

    background-color: var(--primary);

    @include grid(40% 1fr);

    @include phone {
        grid-template-columns: 1fr;
    }

    &__image {
        height: 100%;
        width: 100%;

        @include phone {
            grid-row: 2 / 3;
        }

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    &__content {
        display: flex;
        justify-content: center;

        padding: var(--y-padding) 2.5rem;

        &-container {
            max-width: 55rem;
        }
    }

    &__title {
        color: #fff;
        margin-bottom: 2rem;
    }

    &__text {
        color: rgba(#fff, .7);
        margin-bottom: 4rem;
    }
}